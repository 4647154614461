<template>
    <component
        :is="tag"
        @click.prevent="click"
    >
        <slot />
    </component>
</template>

<script>
export default {
    props: {
        tag: {
            type: String,
            required: true,
        },
        itemId: {
            required: true,
            type: [String, Number],
        },
        method: {
            type: String,
            required: true,
        },
        successMessage: {
            required: false,
            type: [String],
            default: null,
        },
    },
    methods: {
        click() {
            this.jsonRpc(this.method, {id: this.itemId}).then(async (response) => {
                if (this.successMessage) {
                    this.$alertify.success(this.successMessage);
                }
                this.$emit("complete", response);
            })
        },
    },
}
</script>
