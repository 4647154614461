import {v4 as uuidv4} from "uuid"

const JsonRpc = {
    install(Vue) {
        Object.defineProperty(Vue.prototype, "jsonRpc", {
            get: function get() {
                return function (method, params) {
                    return new Promise((resolve, reject) => {
                        const error = (response, code, message, data) => {
                            reject({
                                response,
                                error: {
                                    code,
                                    message,
                                    data,
                                },
                            });
                        };
                        const requestId = uuidv4();
                        Vue.axios({
                            method: "post",
                            url: "/rpc/v1",
                            data: {
                                jsonrpc: "2.0",
                                id: requestId,
                                method,
                                params,
                            }
                        }).then(async (response) => {
                            if (response.status !== 200) {
                                error(response, 0, "Не корректный статус ответа");
                                return;
                            }

                            const data = response.data;

                            if (!data.jsonrpc
                                || data.jsonrpc !== "2.0"
                                || !data.id
                                || data.id !== requestId
                                || (!data.result && !data.error)
                                || (!!data.result && !!data.error)
                            ) {
                                resolve(data);
                                return;
                            }

                            if (data.error) {
                                if (this.$alertify) {
                                    this.$alertify.error(data.error.message)
                                }
                                error(response, data.error.code, data.error.message, data.error.data);

                                return;
                            }

                            resolve(data.result);
                        }).catch((e) => {
                            return error(e.response, 0, "Ошибка при выполнении запроса на сервер");
                        })
                    })
                };
            }
        });
    }
};

export default JsonRpc
